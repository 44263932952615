<main class="container">
 
    <section id="column1">
      <app-page-header></app-page-header>
      <div class="loader-container" *ngIf="isLoader">
        <nz-spin nzTip="Loading..." style="position: fixed;left: 45%;top:50%;z-index: 1000;" [nzSize]="'large'">
          <nz-alert
          nzType="info"
          nzMessage="Loading Law Access Form"
          nzDescription=" "
        ></nz-alert>
        </nz-spin>
      </div>
      <!-- <div class="home-content">
          <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
          <div nz-col [nzSpan]="24"  [nzPush]="8">
            my approval requests
          </div>
        </div>
        <div nz-col [nzSpan]="24"  [nzPush]="1">
          &nbsp;
        </div>
        <div nz-col nzSpan="24">
            <nz-table [nzFrontPagination]="false" [nzData] = "accessRequests">
                <thead>
                    <tr>
                        <th nzWidth="10%">Req No</th>
                        <th>Requestor</th>
                        <th>On Behalf Of</th>
                        <th nzWidth="15%">Requested Date</th>
                        <th nzWidth="15%">Effective Date</th>
                        <th nzWidth="15%">Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of accessRequests">
                        <td ><a class="reqlink" (click)="ViewRequestDetails(data.request_Access_Order_Number)">{{data.request_Access_Order_Number}}</a></td>
                        <td nz-tooltip nzTooltipTitle={{data.requestor}}>{{data.requestor_DisplayName}}</td>
                        <td nz-tooltip nzTooltipTitle={{data.onBehalfOf}}>{{data.on_Behalf_Of_DisplayName}}</td>
                        <td nz-tooltip nzTooltipTitle={{data.requestedDate}}>{{data.createdDate | date: format}}</td>
                        <td nz-tooltip nzTooltipTitle={{data.effectiveDate}}>{{data.effectiveDate | date: format}}</td>
                        <td nz-tooltip nzTooltipTitle={{data.status}}>{{data.status}}</td>
                    </tr>
                </tbody>
            </nz-table>
        </div>
      </div> -->
      <div class="class-content"> 
        <div nz-row nzGutter="8">
          <div nz-col nzSpan="7">
            <form nz-form [formGroup]="searchForm" class="login-form" (ngSubmit)="submitForm()">
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input your Request No">
                  <nz-input-group >
                    <input type="text" nz-input formControlName="requestNo" placeholder="Request No" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input your Requestor">
                  <nz-input-group >
                    <input type="text" nz-input formControlName="requestor" placeholder="Requestor" />
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
             
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input your Effective Date">
                  <nz-input-group >
                    <!-- <input type="text" nz-input formControlName="effectiveDate" placeholder="Effective Date" /> -->
                    <nz-date-picker formControlName="effectiveDate"  nzFormat="MM/dd/yyyy" (ngModelChange)="onChangeEffectiveDate($event)"  nzPlaceHolder="Effective Date"  style="width: 85%" placeholder="12/31/2001" nzspan="24" placeholder="Effective Date">
                    </nz-date-picker>
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input Requested Date">
                  <nz-input-group >
                    <!-- <input type="text" nz-input formControlName="requestedDate" placeholder="Requested Date" /> -->
                    <nz-date-picker formControlName="requestedDate" nzFormat="MM/dd/yyyy"  (ngModelChange)="onChangeRequestedDate($event)" nzPlaceHolder="Requested Date"  style="width: 85%" placeholder="12/31/2001" nzspan="24" placeholder="Requested Date">
                    </nz-date-picker>
                  </nz-input-group>
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control nzErrorTip="Please input your Status!">
                  <nz-tree formControlName="status"
                  [nzData]="nodes"
                  nzCheckable
                  nzMultiple
                  [nzExpandedKeys]="defaultExpandedKeys"
                  [nzCheckedKeys]="defaultCheckedKeys"
                  (nzClick)="nzEvent($event)"
                  (nzExpandChange)="nzEvent($event)"
                  (nzCheckBoxChange)="nzEvent($event)"
                ></nz-tree>
                </nz-form-control>
              </nz-form-item>
              <button nz-button class="login-form-button login-form-margin" [nzType]="'primary'">Search</button>
            </form>
          </div>
          <div nz-col nzSpan="16">
            <nz-table #nzTable *ngIf="accessRequests.length > 0" [nzFrontPagination]="false"  [nzData] = "accessRequests" nzTableLayout="fixed" nzSize="small" nzAlign="center">
              <thead>
                  <tr>
                      <th nzWidth="15%">Req No</th>
                      <th>Requestor</th>
                      <th>On Behalf Of</th>
                      <th nzWidth="15%">Requested Date</th>
                      <th nzWidth="15%">Effective Date</th>
                      <th nzWidth="15%">Status</th>
                  </tr>
              </thead>
              <tbody>
                  <tr *ngFor="let data of accessRequests">
                      <td ><a class="reqlink" (click)="ViewRequestDetails(data.request_Access_Order_Number)">{{data.request_Access_Order_Number}}</a></td>
                      <td nz-tooltip nzTooltipTitle={{data.requestor}}>{{data.requestor_DisplayName}}</td>
                      <td nz-tooltip nzTooltipTitle={{data.onBehalfOf}}>{{data.on_Behalf_Of_DisplayName}}</td>
                      <td nz-tooltip nzTooltipTitle={{data.requestedDate}}>{{data.createdDate | date: format}}</td>
                      <td nz-tooltip nzTooltipTitle={{data.effectiveDate}}>{{data.effectiveDate | date: format}}</td>
                      <td nz-tooltip nzTooltipTitle={{data.status}}>{{data.status}}</td> 
                  </tr>
              </tbody>
          </nz-table>
          <p></p>
          <nz-pagination [nzPageIndex]="pageIndex" [nzPageSize]="pageSize"  (nzPageIndexChange)="getPageIndexNumber($event)" (nzPageSizeChange)="getPageSizeNumber($event)" [nzTotal]="totalCount" [nzSize]="'small'" style="text-align: center;" ></nz-pagination>
          <nz-table *ngIf="accessRequests.length == 0">
            <thead>
              <tr>
                <th nzWidth="15%">Req No</th>
                <th>Requestor</th>
                <th>On Behalf Of</th>
                <th nzWidth="15%">Requested Date</th>
                <th nzWidth="15%">Effective Date</th>
                <th nzWidth="15%">Status</th>
              </tr>
          </thead>
          <tbody>
          </tbody>
          </nz-table>
          </div>
          <div nz-col nzSpan="1"></div>
        </div>
    
      </div>
    </section>
  </main>
  