import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { LFDAPIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { SessionStorageService } from 'src/app/core/services/session_storage';

@Component({
  selector: 'app-allrequests',
  templateUrl: './allrequests.component.html',
  styleUrls: ['./allrequests.component.less']
})
export class AllrequestsComponent {
  isLoader:boolean = false; 
  accessRequests: any[] = [];
  myapprovalRequests: any[] = [];
  mypendingRequests: number =  0
  myreviewCompleteRequests: number = 0
  myclosedRequests: number = 0
  approvalpendingRequests: number =  0
  approvalreviewCompleteRequests: number = 0
  approvalclosedRequests: number = 0
  constructor(private fb: UntypedFormBuilder,private apiService: LFDAPIService, private msgraph: MsGraphService , private authService: CalAngularService,private router: Router, private session: SessionStorageService, private dataservice: DataService) {
  }

  async ngOnInit() {
    let isTokenAvailable = await this.dataservice.getAuthorizationToken();
      if(isTokenAvailable){
      this.isLoader = true;
        this.authService.getUserPropertiesFromMsGraph(["id,displayName","cai", "jobTitle","photo","surname","givenName","officeLocation"]).subscribe(graphProp =>{
          this.getMyRequests();
          this.getMyApprovals();
        });
      }
  }

  getMyRequests(){
    this.apiService.getMyRequests('c843981f-1f58-494d-8adf-af68c7fc7a57').subscribe({
      next: (data) => {
        this.accessRequests = [...data.body];
        console.log('accessRequests', this.accessRequests);
        this.mypendingRequests = this.accessRequests.filter(x=> x.status == 'Pending Review').length;
        this.myreviewCompleteRequests = this.accessRequests.filter(x=> x.status == 'Review Complete').length;
        this.myclosedRequests = this.accessRequests.filter(x=> x.status == 'Closed').length
    },
    error: (err) => {
      console.log("Unable to retrieve my requests", err);
      this.isLoader= false
    },
    complete: () => {
      this.isLoader = false;
    }
  });
  }

  getMyApprovals(){
    this.apiService.getMyApprovals('e4be06d4-19e8-4c05-949e-d97888c10496').subscribe({
      next: (data) => {
        this.myapprovalRequests = [...data.body];
        this.approvalpendingRequests = this.myapprovalRequests.filter(x=> x.status == 'Pending Review').length;
        this.approvalreviewCompleteRequests = this.myapprovalRequests.filter(x=> x.status == 'Review Complete').length;
        this.approvalclosedRequests = this.myapprovalRequests.filter(x=> x.status == 'Closed').length
    },
    error: (err) => {
      console.log("Unable to retrieve my approvals", err);
      this.isLoader= false
    },
    complete: () => {
      this.isLoader = false;
    }
  });
  }
}