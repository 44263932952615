<main class="container">
 
    <section id="column1">
      <app-page-header></app-page-header>
      <div class="loader-container" *ngIf="isLoader">
        <nz-spin nzTip="Loading..." style="position: fixed;left: 45%;top:50%;z-index: 1000;" [nzSize]="'large'">
          <nz-alert
          nzType="info"
          nzMessage="Loading Law Access Form"
          nzDescription=" "
        ></nz-alert>
        </nz-spin>
      </div>
      <div class="home-content">
        <div nz-row >
            &nbsp;
        </div>
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
            <div nz-col [nzSpan]="12" [nzPush]="1">
                my requests
               </div>
                  <div nz-col [nzSpan]="12" [nzPush]="1">
                my approvals
               </div>
            </div>    
           
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
         
            <div nz-col [nzSpan]="12" [nzPush]="1">
                <!-- Use this single form to submit all your access requests for law applications. -->
                <div style="background: #ECECEC; width: 500px; padding:15px;">
                   <div nz-row [nzGutter]="12">
                     <div nz-col [nzSpan]="12">
                       <nz-card class="ant-card-head-title" nzTitle="Pending Review">
                         <p><a><span>{{mypendingRequests}}</span></a></p>
                       </nz-card>
                     </div>
                     <div nz-col [nzSpan]="12">
                       <nz-card class="ant-card-head-title" nzTitle="Review Complete">
                        <p><a><span>{{myreviewCompleteRequests}}</span></a></p>
                       </nz-card>
                     </div>
                     </div>
                     <div nz-row [nzGutter]="12">
                     <div nz-col [nzSpan]="12"  [nzPush]="8">
                       <nz-card class="ant-card-head-title" nzTitle="Closed">
                        <p><a><span>{{myclosedRequests}}</span></a></p>
                       </nz-card>
                     </div>
                   </div>
                 </div>
             </div>
           
          <div nz-col [nzSpan]="12" [nzPush]="1">
             <!-- Use this single form to submit all your access requests for law applications. -->
             <div style="background: #ECECEC; width: 500px; padding:15px;">
                <div nz-row [nzGutter]="12">
                  <div nz-col [nzSpan]="12">
                    <nz-card class="ant-card-head-title" nzTitle="Pending Review">
                        <p><a><span>{{approvalpendingRequests}}</span></a></p>
                    </nz-card>
                  </div>
                  <div nz-col [nzSpan]="12">
                    <nz-card class="ant-card-head-title" nzTitle="Review Complete">
                        <p><a><span>{{approvalreviewCompleteRequests}}</span></a></p>
                    </nz-card>
                  </div>
                  </div>
                  <div nz-row [nzGutter]="12">
                  <div nz-col [nzSpan]="12"  [nzPush]="8">
                    <nz-card class="ant-card-head-title" nzTitle="Closed">
                        <p><a><span>{{approvalclosedRequests}}</span></a></p>
                    </nz-card>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div nz-col [nzSpan]="24"  [nzPush]="1">
          &nbsp;
        </div>
      </div>
    </section> 
  </main>
  