<!-- Header -->
<np-header >
  <!-- Search -->
  <!-- <np-search [searchType]="SearchType.Global"></np-search> -->
  <!-- Global (top menu) Navigation -->
  <np-menu [location]="NavigationLocation.Global"></np-menu>
</np-header>
<!-- Site-Specific Navigation -->
<np-menu  [location]="NavigationLocation.SiteSpecific" ></np-menu>
<!-- Main Content --> 
<router-outlet></router-outlet>
<!-- Footer -->
<!-- [contentContactName]="'Carrie, Ho'" -->
<np-footer  
  [opcoName]="'LAW'"
  [opcoUrl]="'https://chevron.sharepoint.com/sites/LFWeb'"
  [lastUpdate]="'November 1, 2024'"
  [contentContactName]="'Fama, Camille'"
  [contentContactUrl]="'mailto:famacamille@chevron.com'" 
  [technicalContactName]="'Technical content'"
  [technicalContactUrl]="'https://go.chevron.com/LawApplicationSupport'" >
</np-footer>
