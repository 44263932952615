import { Component, ViewChild, EventEmitter, Input, Optional, Output} from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { Router } from '@angular/router';
import { AlertComponent, NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { CalAngularService } from '@cvx/cal-angular';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { differenceInCalendarDays } from 'date-fns';
import { RequestAccess } from 'src/app/core/interfaces/RequestAccess';
import { formatDate } from '@angular/common';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.less'],
  animations: [formControlElement, formLabelElement], 
})

export class HomeComponent {

  isLoader:boolean = false; 
  NavigationLocation = NavigationLocation;
  PageLayout = PageLayout;
  applications:any= [];
  isAddApplicationVisible = false;
  azureObjectId: string = '';
  userName: string = '';
  approverEmail: string = '';
  approverUserName: string = '';
  userRole: string = '';
  onBehalfOfUser: string = '';
  environment = "Production";
  applicationData: any[] = [];
  searchUsers: any[] = [];
  searchApplications: any[] = [];
  isonBehalfOfdisable: boolean = true;
  isCheckedBehalfOf: boolean = false;
  application: any[] = [];
  searchappName: any;
  date = new Date();
  effectiveDate = this.date.setDate(new Date().getDate() + 7);
  isAppSelected: boolean = false;
;
  navigateURL: string = "";
  selectedApp: any;
  checkAllApps: boolean = false;


  reqAcessForm = this.fb.group({
    requestor: [null],
    onBehalfOfUser: [null],
    onBehalfOf: [false],
    title: [null],
    approver: [null],
    environment: [null],
    effectiveDate: [null],
  });

  disabledDate = (current: Date): boolean =>
    differenceInCalendarDays(current, this.effectiveDate) < 0;

  requestAccessFormModel: RequestAccess = {
    id: 0,
    request_Access_Order_Number: '',
    requestor_AzureObjectID: '',
    requestor_DisplayName: '',
    on_Behalf_Of_AzureObjectID: '',
    on_Behalf_Of_DisplayName: '',
    onBehalfOf: false,
    requestor_Title: '',
    location: '',
    approver_DisplayName: '',
    approver_AzureObjectID: '',
    sysid: '',
    environment: 'Production',
    status: 'New',
    effectiveDate: new Date().getDate().toLocaleString("en-US"),
    applicationAccessRequests: [],
    createdBy: '',
    createdDate: new Date().getDate().toLocaleString("en-US"),
    lastModifiedBy: '',
    lastModifiedDate: new Date().getDate().toLocaleString("en-US"),
  };

  
  constructor(private fb: UntypedFormBuilder,private apiService: LFDAPIService, private msgraph: MsGraphService , private authService: CalAngularService,private router: Router, private modal: NzModalService, private session: SessionStorageService, private dataservice: DataService) {
  }

 async ngOnInit() {
    this.isLoader =true;
    let result = await this.dataservice.getAuthorizationToken();
    if(result){
        this.reqAcessForm.controls["requestor"].disable();
        this.reqAcessForm.controls["title"].disable();
        this.reqAcessForm.controls["approver"].disable();
        this.reqAcessForm.controls["onBehalfOfUser"].disable();
        this.reqAcessForm.controls["environment"].disable();
        this.addDays(new Date());
        this.requestAccessFormModel.createdDate = formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US');
        this.requestAccessFormModel.lastModifiedDate = formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US');
        this.getUserDetails();
      }
      this.isLoader = false;
  }

  async getUserDetails(){
    this.authService.getUserPropertiesFromMsGraph(["id,displayName","cai", "jobTitle","photo","surname","givenName","officeLocation"]).subscribe(graphProp =>{
      this.requestAccessFormModel.requestor_AzureObjectID = graphProp.id;
      this.requestAccessFormModel.requestor_DisplayName = graphProp.surname + ", " + graphProp.givenName;
      this.requestAccessFormModel.location = graphProp.officeLocation;
      this.reqAcessForm.controls["requestor"].setValue(this.requestAccessFormModel.requestor_DisplayName);
      this.requestAccessFormModel.requestor_Title = graphProp.jobTitle;
      this.reqAcessForm.controls["title"].setValue(this.requestAccessFormModel.requestor_Title);
      console.log('this.azureObjectId', this.azureObjectId);
      this.msgraph.getRequestorSupervisor(this.requestAccessFormModel.requestor_AzureObjectID).subscribe(data => {
        this.requestAccessFormModel.approver_DisplayName = data.surname + ", " + data.givenName;
        this.requestAccessFormModel.approver_AzureObjectID = data.id;
        this.reqAcessForm.controls["approver"].setValue(this.requestAccessFormModel.approver_DisplayName);
      });
    });
  }

  addApplication() {
    this.isAddApplicationVisible = true;
    this.application = [];
    this.searchApplications = [];
    this.searchappName = '';
  }

  cancelAddApplicationPopup() {
    this.isAddApplicationVisible = false;
  }

  addDays(date: Date): void {
    date.setDate(new Date().getDate() + 7);
    this.reqAcessForm.controls["effectiveDate"].setValue(date);
    
  }

  sendemail(): void {
    this.apiService.sendEmail().subscribe(value => {
      console.log(value);
    });
  }

  submitAccessRequest() {
    this.isLoader = true;
    this.requestAccessFormModel.requestor_DisplayName = this.reqAcessForm.controls["requestor"].value;
    this.requestAccessFormModel.onBehalfOf = this.reqAcessForm.controls["onBehalfOf"].value;
    this.requestAccessFormModel.on_Behalf_Of_DisplayName = this.reqAcessForm.controls["onBehalfOfUser"].value;
    this.requestAccessFormModel.environment = this.reqAcessForm.controls["environment"].value;
    this.requestAccessFormModel.effectiveDate = this.reqAcessForm.controls["effectiveDate"].value;
    this.requestAccessFormModel.applicationAccessRequests = this.applicationData;
    this.requestAccessFormModel.createdBy = this.requestAccessFormModel.requestor_DisplayName;
    this.requestAccessFormModel.lastModifiedBy = this.requestAccessFormModel.requestor_DisplayName;
    this.requestAccessFormModel.createdDate = formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US');
    this.requestAccessFormModel.lastModifiedDate = formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US');
    console.log('this.requestAccessFormModel', this.requestAccessFormModel);
    this.apiService.saveAccessRequest(this.requestAccessFormModel).subscribe({
      next: (out) => {
        console.log("Request submitted successfully", out);
        this.modal.success({
          nzTitle: 'success message',
          nzContent: 'Request submitted successfully',
          nzOnOk: () => this.navigateToPageComponent('/home')
        });
        this.isLoader = false;
      },
      error: (err) => {
        console.log("Unable to submit request", err);
        this.modal.error({
          nzTitle: 'error message',
          nzContent: 'Unable to submit request',
          nzOnOk: () => this.navigateToPageComponent('/home')
        });
        this.isLoader = false;
      },
      complete: () => {
        this.isLoader = false;
    }
  });
}
  
  getApplication() {
    this.isLoader = true;
    this.searchApplications.forEach(val => {
      if(val.appChecked){
        this.applicationData.push({
          appId: val.appId,
          appName: val.appName,
          shortDescription: val.appShortDesc,
          appDescription: val.appDesc,
          appProductLine: val.appProductLine,
          appMimic_DisplayName: "",
          requestor_Comments: "",    
          sysId: val.sysId,
          createdDate: formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US'),
          createdBy: this.requestAccessFormModel.requestor_DisplayName,
          lastModifiedDate: formatDate(new Date().getDate().toLocaleString("en-US"), 'yyyy-MM-dd', 'en-US'),
          lastModifiedBy: this.requestAccessFormModel.requestor_DisplayName,
          attachments: [],
        });
      }
    });
     this.isAddApplicationVisible = false;
     this.isLoader = false;
  }

  selectAllApps(event: boolean) {
    this.isAppSelected = false;
    console.log('event', event);
    this.searchApplications.forEach(val => {
      val.appChecked = event;
      if(val.appChecked){
        this.isAppSelected = true;
      }
    });
  }

  selectApp() {
    this.isAppSelected = false;
    this.searchApplications.forEach(val => {
      if(val.appChecked){
        this.isAppSelected = true;
      }
    });
  }

  onBehalfOf() {
    const control = this.reqAcessForm.controls["onBehalfOf"];
    if (control.value === true) {
      this.reqAcessForm.controls["onBehalfOfUser"].enable();
    }
    else{
      this.reqAcessForm.controls["onBehalfOfUser"].disable();
      this.reqAcessForm.controls["onBehalfOfUser"].setValue('');
      this.getUserDetails();
    }
  }

  onChangeRequestFor(event: any) {
   if (event !== null && event !== undefined && event !== '') {
    console.log('event', event);
      this.isonBehalfOfdisable = true;
      this.msgraph.getGraphUsersByNameOrEmail(event).subscribe({ 
        next: (graphProp) => {
          this.requestAccessFormModel.on_Behalf_Of_AzureObjectID = graphProp.id;
          this.requestAccessFormModel.on_Behalf_Of_DisplayName = graphProp.surname + ", " + graphProp.givenName;
          this.requestAccessFormModel.requestor_Title = graphProp.jobTitle;
          this.msgraph.getRequestorSupervisor(graphProp.id).subscribe({
            next: (data) => {
              this.requestAccessFormModel.approver_AzureObjectID = data.id;
              this.requestAccessFormModel.approver_DisplayName = data.surname + ", " + data.givenName;
              this.reqAcessForm.controls["approver"].setValue(this.requestAccessFormModel.approver_DisplayName);
            },
            error: (err) => {
              this.requestAccessFormModel.approver_AzureObjectID = '';
              this.requestAccessFormModel.approver_DisplayName = '';
              this.reqAcessForm.controls["approver"].setValue('');
              console.log("Unable to graph data", err);
              this.isLoader= false
            },
            complete: () => {
              this.isLoader = false;
            }
          });
        },
        error: (err) => {
          console.log("Unable to graph data", err);
          this.isLoader= false
        },
        complete: () => {
          this.isLoader = false;
        }
    });
  }
  }

  onChangeMimic(event: any, data: any) {
    if (event !== null && event !== undefined && event !== '') {
      console.log('event', event);
      this.msgraph.getGraphUsersByNameOrEmail(event).subscribe(graphProp =>{ // Pass the value as an argument
        data.appMimic_DisplayName = graphProp.surname + ", " + graphProp.givenName;
      });
  }
}

  async searchUser(value: string, calledFrom: string) {
    this.searchUsers = [];
    if (value) {
      let users = [...await this.dataservice.searchUser(value)];
      for (let user of users) {
        this.searchUsers.push({
          defaultEmail: user?.mail,
          defaultName: user?.fullName,
          id: user?.id,
        })
      }
      if (calledFrom === 'onBehalfOf') {
        this.isonBehalfOfdisable = false;
      }
    }
  }

  async searchApplication(event: any) {
    this.searchApplications = [];
    let searchText= $('#txtAppName').val()?.toString();
    if (searchText !== null && searchText !== undefined && searchText !== '') {
      this.isLoader = true;
      this.apiService.getApplications(searchText).subscribe({
        next: (out) => {
          this.application = [...out.body];
          if(this.application.length > 0){
            this.application.forEach(val => {
              this.searchApplications.push({
                appId: Number(val.appId),
                appName: val.name,
                appShortDesc: val.shortDescription.toString().substring(0, 50),
                appChecked: false,
                appDesc: val.shortDescription,
                appProductLine: val.productLine,
                sysId: val.sysId,
              });
            });
          }
        },
      error: (err) => {
        console.log("Unable to retrieve applications", err);
        this.isLoader = false;
      },
      complete: () => {
        this.isLoader = false;
      }
    });
  }
  console.log('searchApplications', this.searchApplications);
  }

  clearApplication()
  {
    this.searchApplications = [];
    $('#txtAppName').val('');
  }

  deleteApp(id: number): void {
    this.applicationData = this.applicationData.filter(d => d.appId !== id);
    this.searchApplications = this.searchApplications.filter(d => d.appId !== id);
  }

  navigateToPageComponent(data: string){
    this.router.navigate([data]);
  }
   
}
 