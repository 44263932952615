import { Component, ViewChild, EventEmitter, Input, Optional, Output} from '@angular/core';
import { LFDAPIService } from 'src/app/core/services/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent, NavigationLocation, PageLayout } from '@cvx/nextpage';
import { SessionStorageService } from 'src/app/core/services/session_storage';
import { DataService } from 'src/app/core/services/data_service';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { PAGE_INDEX, PAGE_SIZE,NO_RESULT, Menu } from 'src/app/constants/constants';
import { NzFormatEmitEvent, NzTreeModule } from 'ng-zorro-antd/tree';
import { routes } from 'src/app/app-routing.module';
import { CalAngularService } from '@cvx/cal-angular';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { formControlElement, formLabelElement } from 'src/app/animations/animations';
import { DateFormat } from 'src/app/constants/constants';
import { isNonEmptyString } from 'ng-zorro-antd/core/util';
import { formatDate } from '@angular/common';

interface SearchRequest {
  approverId: string;
  requestorId: string;
  pageNo: number;
  pageSize: number;
  requestNo: string;
  requestor: string;
  status: string;
  effectiveDate: string;
  requestedDate: string;
}

@Component({
  selector: 'app-myrequests',
  templateUrl: './myrequests.component.html',
  styleUrls: ['./myrequests.component.less'] 
})
export class MyrequestsComponent {
  isLoader:boolean = false;
  accessRequests: any[] = [];
  listOfAccessRequests: any[] = [];
  format:any=DateFormat.mmddyyyy;
  searchForm = this.fb.group({
    requestNo: [],
    requestor: [],
    status: [],
    effectiveDate: [],
    requestedDate: [],
  })
  pageSize:number=PAGE_SIZE;
  pageIndex:number=PAGE_INDEX;
  searchRequest: SearchRequest = {
    approverId:'',
    requestorId: '',
    pageNo: this.pageIndex,
    pageSize: this.pageSize,
    requestNo: '',
    requestor: '',
    status: '',
    effectiveDate: '',
    requestedDate: ''
  };
  totalCount: number = 0;
  statusParam =''; 
  defaultCheckedKeys = [''];
  defaultExpandedKeys = ['Status'];

  readonly nodes = [
    {
      title: 'Status',
      key: 'Status',
      children: [
        { title: 'Pending Review', key: 'Pending Review', isLeaf: true },
        { title: 'Review Complete', key: 'Review Complete', isLeaf: true },
        { title: 'Closed', key: 'Closed', isLeaf: true }
      ]
    }
  ];

  constructor(private fb: UntypedFormBuilder,private apiService: LFDAPIService, private route: ActivatedRoute, private msgraph: MsGraphService , private authService: CalAngularService,private router: Router, private session: SessionStorageService, private dataservice: DataService) {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      this.statusParam = 'Pending Review,Review Complete'
      //this.statusParam.concat('Pending Review,Review Complete')
  });
  }
  
async ngOnInit() {
  let isTokenAvailable = await this.dataservice.getAuthorizationToken();
    if(isTokenAvailable){
    this.isLoader = true;
      this.authService.getUserPropertiesFromMsGraph(["id,displayName","cai", "jobTitle","photo","surname","givenName","officeLocation"]).subscribe(graphProp =>{
        this.searchRequest.requestorId = graphProp.id;
        if (this.statusParam.length >  0  && this.statusParam != undefined) {
          this.defaultCheckedKeys = ['Pending Review','Review Complete'];
          this.searchRequest.status = 'Pending Review,Review Complete';
        }
       this.getAllMyRequests(this.searchRequest);
      });
    }
}

submitForm() {
  let form: UntypedFormGroup=  this.searchForm;
 this.searchRequest.pageNo=this.pageIndex;
 this.searchRequest.requestNo=form.value.requestNo;
 this.searchRequest.requestor=form.value.requestor;
 this.searchRequest.status=this.statusParam.toString();
//  this.searchRequest.effectiveDate=formatDate(form.value.effectiveDate,'MM/dd/yyyy','en-US');
//  this.searchRequest.requestedDate=formatDate(form.value.requestedDate,'MM/dd/yyyy','en-US');
  this.getAllMyRequests(this.searchRequest);
}

getPageIndexNumber(pageIndex:number){
  this.pageIndex=pageIndex;
 let form: UntypedFormGroup=  this.searchForm;
 this.searchRequest.pageNo=pageIndex;
 this.searchRequest.requestNo=form.value.requestNo;
 this.searchRequest.requestor=form.value.requestor;
 this.searchRequest.status=this.statusParam.toString();
 //this.searchRequest.effectiveDate=formatDate(form.value.effectiveDate,'MM/dd/yyyy','en-US');
 //this.searchRequest.requestedDate=formatDate(form.value.requestedDate,'MM/dd/yyyy','en-US');

 this.getAllMyRequests(this.searchRequest);
}
getPageSizeNumber(pageIndex:number){
 this.pageIndex=pageIndex;
 let form: UntypedFormGroup=  this.searchForm;
 this.searchRequest.pageNo=pageIndex;
 this.searchRequest.requestNo=form.value.requestNo;
 this.searchRequest.requestor=form.value.requestor;
 this.searchRequest.status=this.statusParam.toString();
 //this.searchRequest.effectiveDate=formatDate(form.value.effectiveDate,'MM/dd/yyyy','en-US');
 //this.searchRequest.requestedDate=formatDate(form.value.requestedDate,'mm/dd/yyyy','en-US');

 this.getAllMyRequests(this.searchRequest);
}

onChangeEffectiveDate(result: Date): void {
  console.log('onChange: ', result);
  if (result != null)
  {
    this.searchRequest.effectiveDate=formatDate(result,'MM/dd/yyyy','en-US');
  }
  else
  {
    this.searchRequest.effectiveDate='';
  }
}

onChangeRequestedDate(result: Date): void {
  console.log('onChange: ', result);
  if (result != null)
  {
    this.searchRequest.requestedDate=formatDate(result,'MM/dd/yyyy','en-US');
  }
  else
  {
    this.searchRequest.requestedDate='';
  }
}

getAllMyRequests(searchRequest: SearchRequest) {
  console.log('searchRequest', searchRequest);
  //searchRequest.requestorId = 'c843981f-1f58-494d-8adf-af68c7fc7a57';
  this.apiService.getMyRequests(searchRequest).subscribe({
    next: (data) => {
      this.accessRequests = [...data.body];
      this.totalCount = this.accessRequests.length > 0? this.accessRequests[0].totalCount:0;
    },
    error: (err) => {
      this.isLoader = false;
      console.log("Unable to retrieve my requests", err);
    },
    complete: () => {
      this.isLoader = false;
    }
  });
}

nzEvent(event: NzFormatEmitEvent): void {
  console.log(event);
  this.statusParam = '';
  if(event.keys != undefined){
    event.keys.forEach((key) => {
      
        if(key == 'Status')
        {
          this.statusParam = this.statusParam.concat('Pending Review,Review Complete,Closed')
        }
        else
        {
          this.statusParam = this.statusParam.concat(",")
          this.statusParam = this.statusParam.concat(key)
        }
    });
    console.log('statusParam', this.statusParam);
  }
}

ViewRequestDetails(requestAccessId:number){
  this.router.navigate(
    ['/requestapproval'],
    { queryParams: { requestaccessId: requestAccessId } }
  );
}
}

