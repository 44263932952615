import { NgModule } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSegmentedModule } from 'ng-zorro-antd/segmented';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzResultModule } from 'ng-zorro-antd/result';
import { ReactiveFormsModule } from '@angular/forms';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSliderModule } from 'ng-zorro-antd/slider';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { NzCardModule } from 'ng-zorro-antd/card';
import { HomeComponent } from './pages/home/home.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzCommentModule } from 'ng-zorro-antd/comment';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NgxEchartsModule } from 'ngx-echarts';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { CalAngularModule } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzTimelineModule } from 'ng-zorro-antd/timeline';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NextPage } from '@cvx/nextpage';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { NzCarouselModule } from 'ng-zorro-antd/carousel';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { MatDividerModule } from '@angular/material/divider';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { RedirectGuard } from './core/services/RedirectGuard';
import { UnAuthoriseComponent } from './pages/unauthorise/unauthorise.component';
import { MyapprovalsComponent } from './pages/mydashboard/myapprovals/myapprovals.component';
import { RequestapprovalComponent } from './pages/requestapproval/requestapproval.component';
import { MyrequestsComponent } from './pages/mydashboard/myrequests/myrequests.component';
import { LawaccesstoolhomeComponent } from './pages/lawaccesstoolhome/lawaccesstoolhome.component';
import { AllrequestsComponent } from './pages/mydashboard/allrequests/allrequests.component';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageHeaderComponent,
    DateAgoPipe,
    UnAuthoriseComponent,
    MyapprovalsComponent,
    RequestapprovalComponent,
    MyrequestsComponent,
    LawaccesstoolhomeComponent,
    AllrequestsComponent
  ],
  imports: [
    BrowserModule,
    NextPage,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzLayoutModule,
    NzMenuModule,
    NzButtonModule,
    NzSliderModule,
    NzBreadCrumbModule,
    NzCollapseModule,
    NzFormModule,
    NzStepsModule,
    NzCardModule,
    NzInputModule,
    NzSelectModule,
    NzSwitchModule,
    NzUploadModule,
    NzTabsModule,
    NzSegmentedModule,
    NzModalModule,
    NzListModule,
    NzIconModule,
    NzTreeModule,
    NzDatePickerModule,
    NzResultModule,
    ReactiveFormsModule,
    NzTypographyModule,
    NzAvatarModule,
    NzDividerModule,
    NzTimelineModule,
    NzGridModule,
    NzDropDownModule,
    NzTableModule,
    NzCommentModule,
    NzSpaceModule,
    NzImageModule,
    NzDrawerModule,
    NzStatisticModule,
    NzPageHeaderModule,
    NzDescriptionsModule,
    NzNotificationModule,
    NzToolTipModule,
    NzSpinModule,
    NzAlertModule,
    NzPopconfirmModule,
    ScrollingModule,
    NzBadgeModule,
    DragDropModule,
    NzProgressModule,
    NzCarouselModule,
    NzCheckboxModule,
    NzTagModule,
    NzSegmentedModule,
    NzPaginationModule,
    MatDividerModule,
    NzTreeViewModule,
    
    CalAngularModule.forRoot(environment.calConfig),
    LottieModule.forRoot({ player: playerFactory }),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')}
    )
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
     NzMessageService,RedirectGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function playerFactory() {
  return player;
}
