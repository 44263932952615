<main class="container">
 
  <section id="column1">
    <app-page-header></app-page-header>
    <div class="loader-container" *ngIf="isLoader">
      <nz-spin nzTip="Loading..." style="position: fixed;left: 45%;top:50%;z-index: 1000;" [nzSize]="'large'">
        <nz-alert
        nzType="info"
        nzMessage="Loading Law Access Form"
        nzDescription=" "
      ></nz-alert>
      </nz-spin>
    </div>
    <div class="home-content">
      <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
        <div nz-col [nzSpan]="22"  [nzPush]="7">
         law access request form
        </div>
      </div>
      <div nz-row >
        &nbsp;
    </div>
    <legend class="form-row">requestor details</legend>
    <form nz-form [formGroup]="reqAcessForm" nzLayout="vertical">
      <div nz-row nzGutter="8">
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Requestor</nz-form-label>
            <nz-form-control>
              <nz-input-group [nzSuffix]="suffixTemplateUser" style="width: 85%;">
                <input formControlName="requestor" type="text" nz-input [disabled]="true"
                [(ngModel)]="requestAccessFormModel.requestor_DisplayName"  placeholder="requestor" />
              </nz-input-group>
              <ng-template #suffixTemplateUser><span nz-icon nzType="user" style="margin-left: 35px;"></span></ng-template>
            </nz-form-control>
          </nz-form-item>
        </div>
         <div nz-col nzSpan="8" >
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>On Behalf Of</nz-form-label>
            <nz-form-control>
              <nz-switch formControlName="onBehalfOf" [(ngModel)]="requestAccessFormModel.onBehalfOf" (ngModelChange)="onBehalfOf()">  </nz-switch> &nbsp; &nbsp;
              <nz-select  style="width: 70%;" formControlName="onBehalfOfUser" 
              nzShowSearch
              nzServerSearch
              nzPlaceHolder="search user" 
              [(ngModel)]="requestAccessFormModel.on_Behalf_Of_DisplayName"
              (ngModelChange)="onChangeRequestFor($event)"
              (nzOnSearch)="searchUser($event,'onBehalfOf')"
              [nzSuffixIcon]="usrTemplate"
              [disabled]="isonBehalfOfdisable"
            >
            <ng-container *ngFor="let data of searchUsers">
              <nz-option [nzValue]="data.id" [nzLabel]="data.defaultName + ' ' + data.defaultEmail"></nz-option>
            </ng-container>
            </nz-select>
              <!-- <nz-select formControlName="onBehalfOfUser" style="width: 70%;"
              nzShowSearch
              nzServerSearch
              [nzSuffixIcon]="usrTemplate"
              nzPlaceHolder="search user"
              [(ngModel)]="requestAccessFormModel.on_Behalf_Of_DisplayName" 
              (nzOnSearch)="searchUser($event,'onBehalfOf')"
              (ngModelChange)="onChangeRequestFor($event)"
              [disabled]="isonBehalfOfdisable"
            >
            <ng-container *ngFor="let data of searchUsers">
              <nz-option [nzValue]="data.id" [nzLabel]="data.defaultName + ' ' + data.defaultEmail"></nz-option>
            </ng-container>
            </nz-select> -->
            <ng-template #usrTemplate>
              <span nz-icon [nzType]="'user'" [nzTheme]="'outline'"></span>
          </ng-template>
            </nz-form-control>
          
          </nz-form-item>
      </div>
   
      <div nz-col nzSpan="8">
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Title</nz-form-label>
          <nz-form-control>
            <input formControlName="title" type="text" nz-input
              [(ngModel)]="requestAccessFormModel.requestor_Title"  placeholder="title" />
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Approver</nz-form-label>
          <nz-form-control>
            <nz-input-group [nzSuffix]="suffixTemplateUser" style="width: 85%;">
              <input formControlName="approver" type="text" nz-input
              [(ngModel)]="requestAccessFormModel.approver_DisplayName"  placeholder="approver" />
            </nz-input-group>
            <ng-template #suffixTemplateUser><span nz-icon nzType="user" style="margin-left: 35px;"></span></ng-template>
          </nz-form-control>
        </nz-form-item>
        
      </div>
      <div nz-col nzSpan="8" >
        <nz-form-item>
          <nz-form-label [@formLabelAnimation]>Environment</nz-form-label>
          <nz-form-control>
            <input formControlName="environment" type="text" nz-input
              [(ngModel)]="requestAccessFormModel.environment"  placeholder="environment" />
          </nz-form-control>
        </nz-form-item>
        </div>
        <div nz-col nzSpan="8">
          <nz-form-item>
            <nz-form-label [@formLabelAnimation]>Effective Date</nz-form-label>
            <nz-form-control>
                <nz-date-picker formControlName="effectiveDate"  nzFormat="dd-MMM-yyy"  nzPlaceHolder="mm/dd/yyyy"  [nzDisabledDate]="disabledDate" style="width: 85%" placeholder="12/31/2001" nzspan="24" [(ngModel)]="requestAccessFormModel.effectiveDate"  placeholder="effectiveDate">
                </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
          </div>
      </div>
    </form>
    <legend class="form-row">application details</legend>
<br>
    <div nz-row>
      <div (click)="addApplication()" class="sub-header" style="font-size: 15px; cursor: pointer;"> <img class="addappButton" nz-button
         src="assets/Images/add.png" alt="add" /> Add applications
      </div>
    </div>
    <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
    <div nz-col [nzSpan]="24"  [nzPush]="1">
      &nbsp;
    </div>
      <div nz-col nzSpan="24">
          <nz-table nzTemplateMode [nzFrontPagination]="false">
              <thead>
                  <tr>
                    <th nzWidth="15%">Name</th>
                    <th nzWidth="15%">Description</th>
                    <th nzWidth="10%">Product Line</th>
                    <th >Mimic User</th>
                    <th >Comments</th>
                    <th></th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of applicationData">
                  <td nz-tooltip nzTooltipTitle={{data.appName}}>{{data.appId}} - {{data.appName}}</td>
                  <td nz-tooltip nzTooltipTitle={{data.appDescription}} nzTooltipPlacement="right" nzTooltipTrigger="hover" >{{data.shortDescription}}</td>
                  <td nz-tooltip nzTooltipTitle={{data.appProductLine}}>{{data.appProductLine}}</td>
                 
                  <td>
                    <nz-select style="width: 280px;"
                    nzShowSearch
                    nzServerSearch
                    nzPlaceHolder="search user" 
                    [(ngModel)]="data.appMimic_AzureObjectID"
                    (ngModelChange)="onChangeMimic($event,data)"
                    (nzOnSearch)="searchUser($event,'searchUser')"
                    [nzSuffixIcon]="usrTemplate"
                  >
                  <ng-container *ngFor="let data of searchUsers">
                    <nz-option [nzValue]="data.id" [nzLabel]="data.defaultName + ' ' + data.defaultEmail"></nz-option>
                  </ng-container>
                  </nz-select>
                  <ng-template #usrTemplate>
                    <span nz-icon [nzType]="'user'" [nzTheme]="'outline'"></span>
                </ng-template>
                  </td>
                  <td style="width: 280px;"> <textarea nz-input nzAutosize [(ngModel)]="data.requestor_Comments" placeholder="comments"></textarea></td>
                  <td >
                      <img class="editTabImage" src="assets/Images/delete.png" alt="delete" class="adddeleteButton" (click)="deleteApp(data.appId)"/>
                  </td>
              </tr>
              </tbody>
          </nz-table>
          <div nz-col [nzSpan]="24"  [nzPush]="1">
            &nbsp;
          </div>
          <div nz-col nzSpan="12" [nzPush]="10">
            <button class="mainbtnclass" [disabled] ="applicationData.length == 0" nz-button nzType="primary" (click)="submitAccessRequest()">Submit Request &nbsp;&nbsp;</button>
          </div>
        
      </div>
    </div>
      <nz-modal nzCentered="true" 
      [(nzVisible)]="isAddApplicationVisible" [nzWidth] ="665" (nzOnCancel)="cancelAddApplicationPopup()">
      <ng-container *nzModalContent>
        <div class="loader-container" *ngIf="isLoader">
          <nz-spin nzTip="Loading..." style="position: fixed;left: 48%;width:5%;top:50%;z-index: 1000;" [nzSize]="'large'">
            <nz-alert
            nzType="info"
            
            nzDescription=" "
          ></nz-alert>
          </nz-spin>
        </div>
          <div nz-row nzGutter="20">
            <div nz-col nzSpan="20">
              <nz-form-item>
                <nz-form-label [@formLabelAnimation]>Law Application Name</nz-form-label>
                <nz-form-control [@formControlAnimation]>
                <nz-input-group [nzSuffix]="suffixTemplate" style="width: 75%;">
                  <input
                    [type]="'text'"
                    id="txtAppName"
                    nz-input 
                    nz-input [(ngModel)]="searchappName"
                    placeholder="search law applications"
                    (keyup.enter)="searchApplication(this)"
                  />
                </nz-input-group>
                <ng-template #suffixTemplate>
                   <span  nz-icon nzType="search" nzTheme="outline" (click)="searchApplication(this)"></span> 
                   <span  nz-icon nzType="close-circle" nzTheme="outline" (click)="clearApplication();searchappName=null"></span>
                </ng-template>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        <!-- </form> -->
          <div nz-col nzSpan="24">
            <span> Records found : {{searchApplications.length}} </span>
            <nz-table *ngIf="searchApplications.length == 0">
              <thead>
                <tr>
                  <th></th>
                    <th  nzWidth="25%">Name</th>
                    <th>Description</th>
                    <th  nzWidth="25%">Product Line</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            </tbody>
            </nz-table>
            <nz-table  [nzFrontPagination]="false" *ngIf="searchApplications.length > 0" [nzData] = "searchApplications"  [nzScroll]="{ y: '220px' }">
              <thead>
                  <tr>
                   <th nzWidth="7%"><label nz-checkbox  [(ngModel)]="checkAllApps" (ngModelChange)="selectAllApps($event)"></label></th>
                      <th  nzWidth="25%">Name</th>
                      <th>Description</th>
                      <th  nzWidth="25%">Product Line</th>
                  </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of searchApplications">
                  <td><label nz-checkbox [(ngModel)]="data.appChecked" (ngModelChange)="selectApp()"></label></td>
                  <td >{{data.appId}} - {{data.appName}}</td>
                  <td nz-tooltip nzTooltipTitle={{data.appDesc}} nzTooltipPlacement="right" nzTooltipTrigger="hover" >{{data.appShortDesc}}</td>
                  <td >{{data.appProductLine}}</td>
              </tr>
              </tbody>
          </nz-table>
         <br>
        </div>
      </ng-container>
      <ng-container *nzModalFooter>
        <button class="modalclass" [disabled] ="!isAppSelected" nz-button nzType="primary" (click)="getApplication()">Select</button>
        <button class="modalclass" nz-button nzType="primary" (click)="cancelAddApplicationPopup()" >Cancel</button>
      </ng-container>
  </nz-modal>
    </div>

  </section>
</main>
