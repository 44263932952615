import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NextPageRoute, NextPageService } from '@cvx/nextpage';
import { CalGuardService } from '@cvx/cal-angular';
import { HomeComponent } from './pages/home/home.component';
import { SessionStorageService } from './core/services/session_storage';
import { RedirectGuard } from './core/services/RedirectGuard';
import { UnAuthoriseComponent } from './pages/unauthorise/unauthorise.component';
import { DataService } from './core/services/data_service';
import { UnAuthoriseUser } from './core/services/UnAuthoriseUser';
import { MyapprovalsComponent } from './pages/mydashboard/myapprovals/myapprovals.component';
import { RequestapprovalComponent } from './pages/requestapproval/requestapproval.component';
import { MyrequestsComponent } from './pages/mydashboard/myrequests/myrequests.component';
import { LawaccesstoolhomeComponent } from './pages/lawaccesstoolhome/lawaccesstoolhome.component';
import { AllrequestsComponent } from './pages/mydashboard/allrequests/allrequests.component';



export const routes: NextPageRoute[] = [
  {
    path: 'home',
    title: 'home',
    component: LawaccesstoolhomeComponent,
    canActivate: [CalGuardService]
  },
  {
    path: 'requestform',
    title: 'request access',
    component: HomeComponent,
    canActivate: [CalGuardService]
  },
  {
    path: 'my_dashboard',
    title: 'requests',
    children: [
      {
        title: 'my requests', path: 'my_requests', component: MyrequestsComponent,canActivate: [CalGuardService]
      },
      {
        title: 'my approvals', path: 'my_approvals', component: MyapprovalsComponent,canActivate: [CalGuardService]
    }
    ],
      //component: MyrequestsComponent,
    //  canActivate: [CalGuardService]
  },
 
  { path: '', component: LawaccesstoolhomeComponent, pathMatch: 'full',canActivate: [CalGuardService] },
  { path: 'mydashboard', component: MyapprovalsComponent, canActivate: [CalGuardService] },
  { path: 'requestapproval', component: RequestapprovalComponent, canActivate: [CalGuardService] },
  { path: 'my_requests', component: MyrequestsComponent, canActivate: [CalGuardService] },
  { path: 'my_approvals', component: MyapprovalsComponent, canActivate: [CalGuardService] },
  { path: 'unauthorise/error', component: UnAuthoriseComponent, canActivate: [CalGuardService]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private NP: NextPageService,private session: SessionStorageService,private dataservice:DataService) {
    this.NP.options.navigationRoutes = routes;
  } 

 }
