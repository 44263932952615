<main class="container">
 
  <section id="column1">
    <app-page-header></app-page-header>
    <div class="loader-container" *ngIf="isLoader">
      <nz-spin nzTip="Loading..." style="position: fixed;left: 45%;top:50%;z-index: 1000;" [nzSize]="'large'">
        <nz-alert
        nzType="info"
        nzMessage="Loading Law Access Form"
        nzDescription=" "
      ></nz-alert>
      </nz-spin>
    </div>
    <div class="home-content">
        <div nz-row [nzGutter]="{ xs: 8, sm: 16, md: 24, lg: 32 }" nzAlign="top"  class="grid-row" nzJustify="start">
        <!-- <div nz-col [nzSpan]="24"  [nzPush]="9">
          Access Request
        </div> -->
      </div>
     
      <div nz-col nzSpan="24">
        
                <div nz-col [nzSpan]="24" *ngFor="let data of accessRequests">
               
                  <legend class="form-row">request id &nbsp;<i>{{ data.request_Access_Order_Number}} </i>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  status &nbsp; <i>{{ data.status}}</i>
                  </legend>
                    <!-- assigned to </i> &nbsp; approver <i>{{ data.approver_DisplayName}} -->
                <nz-card class="custom-ant-card-body" style="width:100%;height:130px;" [nzBordered]="true">
                  <div nz-row>
                    <div nz-col class="searchGrid" [nzSpan]="8" style="padding-left: 6px;padding-top: 5px;">
                      <!-- <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>id: </b> {{ data.requestId}} </p> -->
                      <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>Requestor: </b> {{ data.requestor_DisplayName}}</p>
                      
                      <p class="custom-ant-card-font-grid" style="font-size:14px;color:black">&nbsp;&nbsp;</p>
                      <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>Environment: </b>{{ data.environment}}</p>

                      <p class="custom-ant-card-font-grid"></p>
                    </div>
                    <div nz-col class="searchGrid" [nzSpan]="8" style="padding-left: 6px;padding-top: 5px;">

                      <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>On Behalf Of: </b>{{ data.on_Behalf_Of_DisplayName}} &nbsp; <span *ngIf="data.on_Behalf_Of_DisplayName" nz-icon nzType="info-circle" nzTheme="outline"></span></p>
                      <p class="custom-ant-card-font-grid" style="font-size:14px;color:black">&nbsp;&nbsp;</p>
                     
                      
                      <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>Effective Date: </b>{{ data.effectiveDate | date:'shortDate'}}</p>
                      
                      <p class="custom-ant-card-font-grid"></p>
                    </div>
                    <div nz-col class="searchGrid" [nzSpan]="8" style="padding-left: 6px;padding-top: 5px;">

                      <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>Title: </b>{{ data.requestor_Title}}</p>

                      <p class="custom-ant-card-font-grid">&nbsp;&nbsp;</p>
                      <p class="custom-ant-card-font-grid" style="font-size:14px;color:black"><b>Approver: </b>{{ data.approver_DisplayName}}</p>
                     
                    </div>
                  </div>
                  
                </nz-card>
              </div>
             
             
               <div nz-col [nzSpan]="24"  [nzPush]="1">
                &nbsp;
               </div>
              <legend class="form-row">application details</legend>
          <div nz-col nzSpan="24">
          <nz-table nzTemplateMode [nzFrontPagination]="false">
            <thead>
                <tr>
                  <th nzWidth="2%"><label *ngIf="requestStatus == 'Pending Review'"nz-checkbox [(ngModel)]="checkAllApps" (ngModelChange)="selectAllApps($event)"></label></th>
                    <th nzWidth="15%">Name</th>
                    <th nzWidth="20%">Mimic User</th>
                    <th nzWidth="20%">Requestor Comments</th>
                    <th nzWidth="25%">Approver Comments</th>
                    <th nzWidth="8%"><span style="color: white;" nz-icon nzType="paper-clip" nzTheme="outline"></span></th>
                    <th nzWidth="15%">SR Number</th>
                    <th nzWidth="15%">SR Status</th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of applicationData">
                
                <td ><label *ngIf="data.status == 'Pending Review'" nz-checkbox [(ngModel)]="data.appChecked" (ngModelChange)="selectApp($event,data)"></label></td>
                <td nz-tooltip nzTooltipTitle={{data.name}}> {{data.appID}} - {{data.appName}} &nbsp;<span nz-icon nzType="info-circle" nzTheme="outline" (click) ="showApplication(data)"></span></td>
                <td nz-disabled nz-tooltip nzTooltipTitle={{data.mimicName}}>{{data.appMimic_DisplayName}}</td>
                <td nz-disabled nz-tooltip nzTooltipTitle={{data.comments}}>{{data.requestor_Comments}}</td>
                <td *ngIf="data.status != 'Pending Review'" nz-disabled nz-tooltip nzTooltipTitle={{data.supervisor_Comments}}>{{data.supervisor_Comments}}</td>
                <td *ngIf="data.status == 'Pending Review'"> <br><span class="mandate" *ngIf="data.appRejected">&nbsp;*&nbsp;</span> <textarea [disabled]="IsReadOnly"  nz-input nzAutosize [(ngModel)]="data.supervisor_Comments" placeholder="supervisor comments"></textarea>
                  <br>
                  <span class="mandate" *ngIf="data.appRejected">&nbsp;&nbsp;Please enter reject comments</span>
                  <br>
                  <!-- <button *ngIf="data.status == 'Pending Review'" class="approveclass" nz-button (click)="UpdateStatus(data,'Approve')" >Approve</button> &nbsp;&nbsp;
                  <button *ngIf="data.status == 'Pending Review'" class="rejectclass" nz-button >Reject</button> -->
                </td>
                <td><span nz-icon nzType="paper-clip" nzTheme="outline" (click)="showAddAttachmentPopup(data)"></span>({{data.attachments.length}})</td>
                <td>
                  <span><a target="_blank" href={{data.serviceNowIDLink}} class="reqlink" >{{data.serviceTaskId}}</a></span> 
              </td>
                <td>
                    <span>{{data.serviceNowRequestStatus}}</span> 
                </td>
            </tr>
            </tbody>
        </nz-table>
          </div>

          <div nz-col nzSpan="24" [nzPush]="8"> 
            <button *ngIf="!IsReadOnly" [disabled]="isapprovalDisable" nzType="primary"  class="mainbtnclass" nz-button (click)="UpdateStatus('Approve')">Approve</button> &nbsp;&nbsp;
            <button *ngIf="!IsReadOnly" [disabled]="isapprovalDisable" nzType="primary"  class="mainbtnclass" nz-button (click)="UpdateStatus('Reject')">Reject</button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button *ngIf="!IsReadOnly" class="mainbtnclass" nz-button nzType="primary" (click)="onBack()">Back</button>
            <button style="right: 190px;" *ngIf="IsReadOnly" class="mainbtnclass" nz-button nzType="primary" (click)="onBack()">Back</button>
            <!-- <button class="modalclass" nz-button nzType="primary" (click)="cancelAddApplicationPopup()" >Cancel &nbsp;&nbsp;</button> -->
          </div>
      </div>
      <nz-modal nzCentered="true" [(nzVisible)]="isAttachmentsVisible"
       [nzWidth] ="665" (nzOnCancel)="cancelAddAttachmentPopup()">
      <ng-container *nzModalContent>
        <div class="loader-container" *ngIf="isLoader">
          <nz-spin nzTip="Loading..." style="position: fixed;left: 48%;width:5%;top:50%;z-index: 1000;" [nzSize]="'large'">
            <nz-alert
            nzType="info"
            nzDescription=" "
          ></nz-alert>
          </nz-spin>
        </div>
          <div nz-row nzGutter="20">
            <div nz-col nzSpan="20">
              <nz-form-item>
                <nz-form-label [@formLabelAnimation]>Attachments</nz-form-label>
                <div nz-row>
                  <nz-upload *ngIf="showAttachmentDelete" nzType="drag" [nzMultiple]="true" [nzCustomRequest]="nzAction" [nzShowUploadList]="false" >
                    <p class="ant-upload-drag-icon">
                      <span nz-icon nzType="inbox"></span>
                    </p>
                    <p class="ant-upload-text">Click or drag file to this area to upload</p>
                    <p class="ant-upload-hint">
                      Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                    </p>
                  </nz-upload>
                  <nz-form-label *ngIf="showAttachmentDelete" class="attachmentTip" [@formLabelAnimation] nzTooltipTitle="File size limit is 4GB." ></nz-form-label>            
                </div>
                <nz-space nzDirection="vertical" nzSize="large"></nz-space>
                <div nz-row *ngFor="let file of attachments">
                  <div nz-col nzSpan="40">
                    <a [href]="file.fileURL"><span nz-icon nzType="paper-clip" nzTheme="outline"></span>&nbsp;{{file.fileName}}</a>
                  </div>
                  <div nz-col nzOffset="1" nzSpan="15">   
                    <a><span *ngIf="showAttachmentDelete" (click) ="deleteAttachment(file)" nz-icon nzType="delete" nzTheme="outline" style="color: red;" ></span></a>
                  </div>
                </div>
              </nz-form-item>
            </div>
          </div>
      </ng-container>
      <ng-container *nzModalFooter>
        <button class="modalclass" nz-button nzType="primary" (click)="cancelAddAttachmentPopup()" >Close</button>
      </ng-container>
  </nz-modal>

  <nz-modal nzCentered="true" [(nzVisible)]="isShowApplication" nzTitle="Application Details"
       [nzWidth] ="500" (nzOnCancel)="cancelShowApplication()">
      <ng-container *nzModalContent>
        <div class="loader-container" *ngIf="isLoader">
          <nz-spin nzTip="Loading..." style="position: fixed;left: 48%;width:5%;top:50%;z-index: 1000;" [nzSize]="'large'">
            <nz-alert
            nzType="info"
            nzDescription="Application Details"
          ></nz-alert>
          </nz-spin>
        </div>
          <div nz-row nzGutter="20">
            <div nz-col nzSpan="20">
              <span><b>App ID: </b></span>
              <span>{{application.appID}}</span>
            </div>
            </div>
            <p nzSpan="20">&nbsp;</p>
            <div nz-row nzGutter="20">
           
            <div nz-col nzSpan="20">
              <span><b>App Name: </b></span>
              <span>{{application.appName}}</span>
            </div>
            </div>
            <p nzSpan="20">&nbsp;</p>
            <div nz-row nzGutter="20">
         
            <div nz-col nzSpan="20">
              <span><b>App Description: </b></span>
              <span>{{application.appDescription}}</span>
            </div>
            </div>
            <p nzSpan="20">&nbsp;</p>
            <div nz-row nzGutter="20">
           
            <div nz-col nzSpan="20">
              <span><b>App ProductLine: </b></span>
              <span>{{application.appProductLine}}</span>
            </div>
            </div>
      </ng-container>
      <ng-container *nzModalFooter>
        <button class="modalclass" nz-button nzType="primary" (click)="cancelShowApplication()" >Close</button>
      </ng-container>
  </nz-modal>
      
    </div>

  </section>
</main>
