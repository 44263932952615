import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';
import { LFDAPIService } from 'src/app/core/services/api';
import { DataService } from 'src/app/core/services/data_service';
import { MsGraphService } from 'src/app/core/services/ms-graph';
import { SessionStorageService } from 'src/app/core/services/session_storage';

interface SearchRequest {
  approverId: string;
  requestorId: string;
  pageNo: number;
  pageSize: number;
  requestNo: string;
  requestor: string;
  status: string;
  effectiveDate: string;
  requestedDate: string;
}

@Component({
  selector: 'app-lawaccesstoolhome',
  templateUrl: './lawaccesstoolhome.component.html',
  styleUrls: ['./lawaccesstoolhome.component.less']
})

export class LawaccesstoolhomeComponent {
  isLoader:boolean = false; 
  accessRequests: any[] = [];
  myapprovalRequests: any[] = [];
  mypendingRequests: number =  0
  myreviewCompleteRequests: number = 0
  myclosedRequests: number = 0
  approvalpendingRequests: number =  0
  approvalreviewCompleteRequests: number = 0
  approvalclosedRequests: number = 0
  pageSize:number=1000;
  pageIndex:number=1;
  searchRequest: SearchRequest = {
    approverId:'',
    requestorId: '',
    pageNo: this.pageIndex,
    pageSize: this.pageSize,
    requestNo: '',
    requestor: '',
    status: '',
    effectiveDate: '',
    requestedDate: ''
  };
  constructor(private fb: UntypedFormBuilder,private apiService: LFDAPIService, private msgraph: MsGraphService , private authService: CalAngularService,private router: Router, private session: SessionStorageService, private dataservice: DataService) {
  }

  async ngOnInit() {
    let isTokenAvailable = await this.dataservice.getAuthorizationToken();
      if(isTokenAvailable){
      this.isLoader = true;
        this.authService.getUserPropertiesFromMsGraph(["id,displayName","cai", "jobTitle","photo","surname","givenName","officeLocation"]).subscribe(graphProp =>{
          this.searchRequest.requestorId = graphProp.id;
          this.getMyRequests();
          this.getMyApprovals();
        });
      }
  }

  getMyRequests(){
    //this.searchRequest.requestorId = 'c843981f-1f58-494d-8adf-af68c7fc7a57';
    this.apiService.getMyRequests(this.searchRequest).subscribe({
      next: (data) => {
        this.accessRequests = [...data.body];
        console.log('accessRequests', this.accessRequests);
        this.mypendingRequests = this.accessRequests.filter(x=> x.status == 'Pending Review' || x.status == 'Review Complete').length;
    },
    error: (err) => {
      console.log("Unable to retrieve my requests", err);
      this.isLoader= false
    },
    complete: () => {
      this.isLoader = false;
    }
  });
  }

  getMyApprovals(){
    this.searchRequest.approverId = 'e4be06d4-19e8-4c05-949e-d97888c10496';
    this.apiService.getMyApprovals(this.searchRequest).subscribe({
      next: (data) => {
        this.myapprovalRequests = [...data.body];
        this.approvalpendingRequests = this.myapprovalRequests.filter(x=> x.status == 'Pending Review').length;
        this.approvalreviewCompleteRequests = this.myapprovalRequests.filter(x=> x.status == 'Review Complete').length;
    },
    error: (err) => {
      console.log("Unable to retrieve my approvals", err);
      this.isLoader= false
    },
    complete: () => {
      this.isLoader = false;
    }
  });
  }

  ViewMyRequests(status:string){
    this.router.navigate(
      ['/my_requests'],
      { queryParams: { status: status } }
    );
  }

  ViewMyApprovalRequests(status:string){
    this.router.navigate(
      ['/my_approvals'],
      { queryParams: { status: status } }
    );
  }
}